import type { ToastOptions as HotToastOptions } from 'react-hot-toast';
import toast from 'react-hot-toast';
import { GoInfo as IconInfo } from 'react-icons/go';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Box, Flex, Grid } from '@odo/components/elements/layout';
import Button from '@odo/components/elements/button';
import { FaTimes as IconClose } from 'react-icons/fa';
import uuid from '@odo/utils/uuid';
import { conditionalJoin } from '@odo/utils/string';
import { cssColor } from '@odo/utils/css-color';

export const dismiss = (id?: string) => toast.dismiss(id);

interface MessageBodyOptions {
  dismissible?: boolean;
  onDismiss?: () => void;
  action?: {
    label: string;
    callback: () => void;
  };
  /**
   * NOTE: actions will override the action object with a custom ReactNode
   */
  actions?: ReactNode;
}

type ToastOptions = HotToastOptions & {
  messageOptions?: MessageBodyOptions;
};

type ToastHandler = (message: ReactNode, options?: ToastOptions) => string;

const MessageBody = ({
  message,
  id,
  actions,
  dismissible,
  onDismiss,
}: {
  message: ReactNode;
  id?: string;
} & Omit<MessageBodyOptions, 'action'>) => (
  <>
    {!!actions || !!dismissible ? (
      <Grid
        justifyContent="space-between"
        gridTemplateColumns={conditionalJoin(['1fr', ['auto', !!dismissible]])}
        alignItems="center"
        gap={[2, 3]}
      >
        <Flex flexDirection="column" gap={[2]}>
          <Box>{message}</Box>
          {actions}
        </Flex>

        {dismissible && id && (
          <Button
            hue="dark-grey"
            variant="flat"
            px={0}
            py={0}
            noCasing
            onClick={() => {
              onDismiss && onDismiss();
              dismiss(id);
            }}
          >
            <IconClose size={12} color={cssColor('grey')} />
          </Button>
        )}
      </Grid>
    ) : (
      <>{message}</>
    )}
  </>
);

const generateOptions = (
  options: ToastOptions,
  defaultOptions: ToastOptions
): ToastOptions => {
  // explicit dismissible, with default fallback, else false
  const hasDismissible =
    typeof options?.messageOptions?.dismissible !== 'undefined';
  const dismissible = hasDismissible
    ? options?.messageOptions?.dismissible
    : defaultOptions?.messageOptions?.dismissible || false;

  // explicit id, with uuid generation fallback, else false
  const id = options.id || (dismissible && uuid());

  // default styles (with inversion if explicitly requested)
  // dismissible styles
  // then explicit styles
  const style = {
    ...(dismissible && { paddingRight: '2px' }),
    ...options?.style,
  };

  return {
    ...defaultOptions,
    ...options,
    ...(id && { id }),
    style,
    messageOptions: {
      ...defaultOptions?.messageOptions,
      ...options?.messageOptions,
    },
  };
};

const DefaultAction = ({
  label,
  callback,
}: {
  label: string;
  callback: () => void;
}) => (
  <Button hue="blue" variant="flat" px={0} py={0} noCasing onClick={callback}>
    {label}
  </Button>
);

const createActions = (options: ToastOptions): ReactNode | undefined => {
  if (!options?.messageOptions) return;

  const { actions, action } = options.messageOptions;

  if (actions) return actions;
  if (action) {
    return <DefaultAction label={action.label} callback={action.callback} />;
  }
};

const defaultNotificationOptions: ToastOptions = {
  icon: (
    <IconInfo
      color={cssColor('palette-blue')}
      size={18}
      style={{ flexShrink: 0 }}
    />
  ),
  duration: 4500,
};

export const notification: ToastHandler = (message, options = {}) => {
  const toastOptions = generateOptions(options, defaultNotificationOptions);
  return toast.success(
    <MessageBody
      message={message}
      id={toastOptions.id}
      actions={createActions(options)}
      {...toastOptions?.messageOptions}
    />,
    toastOptions
  );
};

const defaultLoadingOptions: ToastOptions = {
  iconTheme: {
    primary: cssColor('off-white'),
    secondary: cssColor('palette-blue'),
  },
};

export const invertedLoadingColors: ToastOptions = {
  style: {
    background: cssColor('palette-blue'),
    color: cssColor('pure-white'),
  },
  iconTheme: {
    primary: cssColor('pure-white'),
    secondary: cssColor('palette-blue-light'),
  },
};

export const loading: ToastHandler = (message, options = {}) => {
  const toastOptions = generateOptions(options, defaultLoadingOptions);
  return toast.loading(
    <MessageBody
      message={message}
      id={toastOptions.id}
      actions={createActions(options)}
      {...toastOptions?.messageOptions}
    />,
    toastOptions
  );
};

const defaultSuccessOptions: ToastOptions = {
  iconTheme: {
    primary: cssColor('palette-turquoise'),
    secondary: cssColor('pure-white'),
  },
};

export const invertedSuccessColors: ToastOptions = {
  style: {
    background: cssColor('palette-turquoise'),
    color: cssColor('pure-white'),
  },
  iconTheme: {
    primary: cssColor('pure-white'),
    secondary: cssColor('palette-turquoise'),
  },
};

export const success: ToastHandler = (message, options = {}) => {
  const toastOptions = generateOptions(options, defaultSuccessOptions);
  return toast.success(
    <MessageBody
      message={message}
      id={toastOptions.id}
      actions={createActions(options)}
      {...toastOptions?.messageOptions}
    />,
    toastOptions
  );
};

const defaultErrorOptions: ToastOptions = {
  duration: Infinity,
  position: 'bottom-right',
  messageOptions: { dismissible: true },
};

/**
 * NOTE: RP has a stupid general.css entry that applies the grey/black color to all divs
 * that is breaking this color application coz of how color inheritance works.
 * We can't quite remove that right now, and I'd rather not build some overly complex
 * implementation just to circumvent it. So this inversion doesn't work for now.
 */
export const invertedErrorColors: ToastOptions = {
  style: {
    background: cssColor('palette-red'),
    color: cssColor('pure-white'),
  },
  iconTheme: {
    primary: cssColor('pure-white'),
    secondary: cssColor('palette-red'),
  },
};

export const error: ToastHandler = (message, options = {}) => {
  const toastOptions = generateOptions(options, defaultErrorOptions);
  return toast.error(
    <MessageBody
      message={message}
      id={toastOptions.id}
      actions={createActions(options)}
      {...toastOptions?.messageOptions}
    />,
    toastOptions
  );
};

interface Timing {
  timeInMs: number;
  timedOut?: () => void;
}

const Timer = ({
  timeInMs,
  timedOut,
  id,
  dismissible,
}: Timing &
  Pick<ToastOptions, 'id'> &
  Pick<MessageBodyOptions, 'dismissible'>) => {
  const [time, setTime] = useState<number | undefined>(undefined);

  /**
   * Interval for countdown and callback when complete.
   */
  useEffect(() => {
    setTime(timeInMs);

    const intervalId = setInterval(() => {
      setTime(time => {
        if (!time) return time;

        const nextTime = time - 1000;
        if (nextTime <= 0) {
          clearInterval(intervalId);

          // trigger our callback function
          timedOut && timedOut();

          // defer the dismissal to fix a race condition between react & react-hot-toast
          dismissible && id && setTimeout(() => dismiss(id), 0);
        }
        return nextTime;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeInMs, timedOut, id, dismissible]);

  if (!time) return null;

  return (
    <span
      style={{
        minWidth: '30px',
        textAlign: 'center',
        fontWeight: '700',
        fontFamily:
          'Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace',
        fontVariantNumeric: 'tabular-nums',
      }}
    >
      <b>{(time / 1000).toString()}</b>s
    </span>
  );
};

type TimerToastOptions = HotToastOptions &
  Timing & {
    messageOptions?: MessageBodyOptions;
  };

type TimerToastHandler = (
  message: ReactNode,
  options: TimerToastOptions
) => string;

const defaultTimerOptions: ToastOptions = {
  duration: Infinity,
  position: 'bottom-right',
  messageOptions: { dismissible: true },
};

export const timer: TimerToastHandler = (message, options) => {
  const { timeInMs, timedOut, ...restOptions } = options;
  const toastOptions = generateOptions(restOptions, defaultTimerOptions);
  return toast.loading(
    <MessageBody
      message={message}
      id={toastOptions.id}
      actions={createActions(options)}
      {...toastOptions?.messageOptions}
    />,
    {
      icon: (
        <Timer
          timeInMs={timeInMs}
          timedOut={timedOut}
          id={toastOptions.id}
          dismissible={toastOptions.messageOptions?.dismissible}
        />
      ),
      ...toastOptions,
    }
  );
};
