/**
 * Port from PHP of the ODO Price Calculator.
 * @see https://gitlab.com/onedayonly/magento_1/-/blob/master/odo/pages/price_calculator.php
 * @see https://gitlab.com/onedayonly/magento_1/-/blob/master/odo/resources/price-calculator/includes.php
 *
 * TODO: margin -> profit calculator (inverse of calcProfit essentially)
 */
import {
  calcSavingsInPercentage,
  calcSavingsInRands,
  calcSurchargeInsurance,
} from '@odo/helpers/calculations/general';
import { VAT_RATE, PAYMENT_METHOD_COSTS, DEFAULT_ADMIN_COST } from './config';
import type {
  RandsPercentage,
  Margin,
  ProfitCalculatorResult,
  ProfitCalculatorArgs,
} from './types';

// NOTE: the admin cost saved on the deal is an enum or an ID, neither help here, but RP had this, so re-using
export const getAdminCostValue = (input: unknown) => {
  if (typeof input !== 'string') return DEFAULT_ADMIN_COST;
  if (input.match(/34_00/g)) return 34.0;
  if (input.match(/17_50/g)) return 17.5;
  if (input.match(/10_00/g)) return 10.0;
  if (input.match(/0_00/g)) return 0;
  if (input.match(/none/gi)) return 0;
  return DEFAULT_ADMIN_COST;
};

const twoPoint = (val: number) => +val.toFixed(2);

const percentageOf = (value: number, percentageOf: number) =>
  twoPoint((value / percentageOf) * 100);

const calcWeightedAverage = ({
  price,
  margins,
}: {
  price: number;
  margins: Margin[];
}): RandsPercentage => {
  const weightedAverage = margins.reduce(
    (agg, { weight, profitMargin: { raw: margin } }) =>
      weight ? (agg += margin * weight) : agg,
    0
  );

  return {
    raw: weightedAverage,
    rands: twoPoint(weightedAverage),
    percentage: percentageOf(weightedAverage, price),
  };
};

export const calcProfit = ({
  cost,
  price,
  retail,
  stock,
  rebate = 0,
  surcharge: surchargeInput = 0,
  adminCost = DEFAULT_ADMIN_COST,
  vatIncluded = true,
  calcInsurance = false,
}: ProfitCalculatorArgs): ProfitCalculatorResult => {
  // if we need to calculate insurance have that override the surcharge value
  const surcharge = calcInsurance
    ? calcSurchargeInsurance(cost)
    : surchargeInput;

  // if vatIncluded is false, then the price/surcharge will be divided by 1
  const priceLessVat = price / (1 + VAT_RATE * +vatIncluded);
  const surchargeLessVat = surcharge / (1 + VAT_RATE * +vatIncluded);

  // if rebate is 0, then cost will be multiplied by 1
  const costLessRebate = cost * (1 - rebate / 100);

  const baseMargin =
    priceLessVat + surchargeLessVat - costLessRebate - adminCost;

  /**
   * Each payment method will have different margins as they each have different charges.
   * There is a percentage and a flat surcharge which each need to be applied to the base margin (in that order).
   *
   * The weight is essentially how many orders we "get" for the payment method as a percentage.
   * And is used to calculate our weighted average margin.
   */
  const paymentMethodMargins = Object.entries(PAYMENT_METHOD_COSTS).map(
    ([code, { label, percentage, surcharge, weight }]) => {
      const margin = baseMargin - (price * percentage) / 100 - surcharge;

      return {
        code,
        label,
        weight,
        profitMargin: {
          raw: margin,
          rands: twoPoint(margin),
          percentage: percentageOf(margin, price),
        },
      };
    }
  );

  const weightedAverageMargin = calcWeightedAverage({
    price,
    margins: paymentMethodMargins,
  });

  const profitPotential =
    typeof stock !== 'undefined'
      ? twoPoint(weightedAverageMargin.rands * stock)
      : undefined;

  const customerSavings =
    typeof retail !== 'undefined'
      ? {
          raw: retail - price,
          rands: calcSavingsInRands(price, retail),
          percentage: calcSavingsInPercentage(price, retail),
        }
      : undefined;

  return {
    weightedAverage: weightedAverageMargin,
    profitPotential,
    margins: paymentMethodMargins,
    customerSavings,
  };
};
