export const SCRAPE_PAGE_COUNT = 5;

/**
 * NOTE: we need to proxy this through cloudflare for local dev
 * but I'm honestly not certain we can go direct even outside of local dev
 * so I'm planning to keep this proxy going for now.
 *
 * the proxy itself only adds a CORS header for allowing follow through,
 * then sends the request directly to the provider without adding any extra auth parameters.
 */
export const SCRAPER_URL = process.env.REACT_APP_OXYLABS_SCRAPER_PROXY_URL;

/**
 * storing the auth key in the .env is insecure
 * as it will be included in our JS bundles
 * which are publicly available, even if the inner workings of the portal are NOT.
 * TODO: implement secure storage for the auth token & invalidate this specific token afterwards.
 */
export const SCRAPER_AUTH_TOKEN =
  process.env.REACT_APP_OXYLABS_SCRAPER_AUTH_TOKEN || '';

// all their images seem to come in this size, we use this just to control our containers
export const GOOGLE_IMAGE_DIMS_PX = 92;

// TODO: update comparison code to not care about protocol or subdomain/www
export const COMPARE_BLACKLIST = [
  'https://www.onedayonly.co.za',
  'https://www.quora.com',
  'https://pricecheck.co.za',
  'https://gumtree.co.za',
  'https://za.shein.com',
  'https://www.temu.com',
  'https://www.bobshop.co.za',
  'https://www.importitall.co.za',
  'https://www.wantitall.co.za',
  'https://www.desertcart.co.za',
  'https://www.lightinthebox.com',
  'https://www.banggood.com',
  'https://www.aliexpress.com',
  'https://www.amazon.com',
  'https://www.alibaba.com',
  'https://www.ebay.com',
  'https://www.gumtree.co.za',
  'https://www.pricecheck.co.za',
  'https://www.croma.com',
  'https://www.pinterest.com',
  'https://www.ubuy.za.com',
  'https://www.cashconverters.co.za',
];

// our main competitors
export const COMPARE_WHITELIST_PRIMARY = [
  'takealot.com',
  'yuppiechef.com',
  'superbalist.com',
  'woolworths.co.za',
  'makro.co.za',
  'game.co.za',
  'amazon.co.za',
  'bash.com',
  'myrunway.co.za',
];

// our secondary competitors
export const COMPARE_WHITELIST_SECONDARY = [
  'leroymerlin.co.za',
  'hirschs.co.za',
  'tafelberg.co.za',
  'incredible.co.za',
  'builders.co.za',
  'metrohomecentre.com',
  'mhcworld.co.za',
  'clicks.co.za',
  'dischem.co.za',
  'loot.co.za',
  'sportsmanswarehouse.co.za',
  'theathletesfoot.co.za',
  'capeunionmart.co.za',
  'kloppers.co.za',
  'toysrus.co.za',
  'greatyellowbrick.co.za',
  'toykingdom.co.za',
  'pnp.co.za',
  'checkers.co.za',
  'istore.co.za',
  'digicape.co.za',
  'shopandship.co.za',
  'technomobi.co.za',
];
