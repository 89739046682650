import {
  useState,
  type ReactNode,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import type {
  BackgroundColorProps,
  BorderProps,
  BoxShadowProps,
  LayoutProps,
} from '@odo/lib/styled';
import styled, {
  color,
  border,
  compose,
  layout,
  boxShadow,
} from '@odo/lib/styled';
import type { OffsetOptions, Placement, ShiftOptions } from '@floating-ui/dom';
import {
  autoUpdate,
  computePosition,
  offset,
  flip,
  shift,
} from '@floating-ui/dom';
import { cssColor } from '@odo/utils/css-color';

type DropdownWrapperProps = BackgroundColorProps &
  LayoutProps &
  BorderProps &
  BoxShadowProps;

const DropdownWrapper = styled.div<DropdownWrapperProps>`
  z-index: 100;
  box-sizing: border-box;
  position: absolute;
  width: max-content;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: all;

  ${compose(color, layout, border, boxShadow)}
`;

DropdownWrapper.defaultProps = {
  backgroundColor: cssColor('foreground'),
  maxWidth: 'calc(100vw - 16px)',
  borderRadius: '3px 3px 6px 6px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: cssColor('grey-ultra-light'),
  boxShadow:
    '1px 2px 4px -2px hsl(240deg 33.33% 20% / 25%), 1px 2px 8px -2px hsl(240deg 33.33% 20% / 10%)',
};

const Dropdown = ({
  children,
  content,
  placement: placementProp = 'bottom',
  disabled,
  offset: offsetProp = 4,
  shift: shiftProp = { padding: 5 },
  isOpen: isOpenProp,
  setIsOpen: setIsOpenProp,
  disableCloseOnOutsideClick,
  ...rest
}: {
  children: ReactNode;
  content: (props: { close: () => void }) => ReactNode;
  placement?: Placement;
  disabled?: boolean;
  offset?: OffsetOptions;
  shift?: ShiftOptions;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  disableCloseOnOutsideClick?: boolean;
} & DropdownWrapperProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpenInternal] = useState(false);

  if (typeof isOpenProp !== 'undefined' && isOpen !== isOpenProp) {
    setIsOpenInternal(isOpenProp);
  }

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setIsOpenInternal(isOpen);
      setIsOpenProp && setIsOpenProp(isOpen);
    },
    [setIsOpenProp]
  );

  /**
   * Placement bindings.
   */
  useEffect(() => {
    if (!isOpen || !anchorRef.current || !dropdownRef.current) return;

    const anchor = anchorRef.current;
    const dropdown = dropdownRef.current;

    const cleanup = autoUpdate(anchor, dropdown, () => {
      computePosition(anchor, dropdown, {
        placement: placementProp,
        middleware: [offset(offsetProp), flip(), shift(shiftProp)],
      }).then(({ x, y }) => {
        /**
         * TODO: look into using transform properties instead:
         * @see https://floating-ui.com/docs/misc#subpixel-and-accelerated-positioning
         */
        Object.assign(dropdown.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        // enter animation
        // NOTE: no styles added for now, so there is no actual animation
        // but it can easily be added with CSS if and when we feel.
        setTimeout(() => dropdown.classList.add('active'), 10);
      });
    });

    return () => cleanup();
  }, [disabled, isOpen, placementProp, offsetProp, shiftProp]);

  /**
   * Close on escape keypress or outside click.
   */
  useEffect(() => {
    if (!isOpen || disableCloseOnOutsideClick) return;

    const outsideClickClose = (e: MouseEvent | TouchEvent) => {
      if (
        !anchorRef.current ||
        !dropdownRef.current ||
        !(e.target instanceof Element)
      ) {
        return;
      }

      if (
        !anchorRef.current.contains(e.target) &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', outsideClickClose);
    document.addEventListener('touchstart', outsideClickClose);

    return () => {
      document.removeEventListener('mousedown', outsideClickClose);
      document.removeEventListener('touchstart', outsideClickClose);
    };
  }, [isOpen, setIsOpen, disableCloseOnOutsideClick]);

  return (
    <>
      <div ref={anchorRef} onClick={() => !disabled && setIsOpen(!isOpen)}>
        {children}
      </div>

      {isOpen && (
        <DropdownWrapper ref={dropdownRef} {...rest}>
          {content({ close: () => setIsOpen(false) })}
        </DropdownWrapper>
      )}
    </>
  );
};

export default Dropdown;
