/**
 * NOTE: new version of the custom options toolbar.
 * Is designed to be put in the app container as a fixed footer.
 * Also uses the new button components.
 */
import {
  FaUndo as IconUndo,
  FaRedo as IconRedo,
  FaCheckDouble as IconAutoSumOn,
  FaBan as IconAutoSumOff,
} from 'react-icons/fa';
import { MdDriveFileMove as IconPaste } from 'react-icons/md';
import {
  ActionTypeEnum,
  useAddActionDebounced,
  useCustomOptionsEditorContext,
} from '@odo/contexts/custom-options-editor';
import uuid from '@odo/utils/uuid';
import { Hidable } from '@odo/screens/deal/editor/price-and-custom-options/styles';
import Button from '@odo/components/elements/button';
import styled from '@odo/lib/styled';
import { Flex } from '@odo/components/elements/layout';
import { cssColor } from '@odo/utils/css-color';
import { useIsMobile } from '@odo/hooks/use-media';

const ToolbarSeparator = styled.span`
  height: 24px;
  width: 1px;
  background: ${cssColor('border')};
`;

const CustomOptionsToolbar = () => {
  const isMobile = useIsMobile();

  const {
    autoSumEnabled,
    canUndo,
    canRedo,
    canClearActions,
    productMeta,
    moveActionOffset,
    copyingOptionId,
    pasteOption,
    clearActions,
    toggleAutoSumEnabled,
  } = useCustomOptionsEditorContext();

  const addAction = useAddActionDebounced();

  if (!productMeta) {
    return null;
  }

  return (
    <Flex
      flexDirection={['column', 'row']}
      justifyContent={['flex-start', 'space-between']}
      gap={2}
      p={2}
    >
      <Flex
        alignItems="center"
        gap={[1, 2]}
        justifyContent={['space-between', 'flex-start']}
      >
        <Button
          hue="blue"
          variant="solid"
          disabled={!canUndo}
          onClick={() => canUndo && moveActionOffset(-1)}
        >
          <IconUndo />
          {!isMobile && 'UNDO'}
        </Button>

        <Button
          hue="blue"
          variant="solid"
          disabled={!canRedo}
          onClick={() => canRedo && moveActionOffset(+1)}
        >
          <IconRedo />
          {!isMobile && 'REDO'}
        </Button>

        <ToolbarSeparator />

        <Button
          hue="blue"
          variant="outlined"
          onClick={() =>
            addAction({
              type: ActionTypeEnum.AddOption,
              tmpOptionId: uuid(),
              tmpValueId: uuid(),
              productId: productMeta.id,
            })
          }
        >
          NEW OPTION
        </Button>

        <Hidable
          className={!isMobile && !copyingOptionId ? 'hidden' : undefined}
        >
          <ToolbarSeparator />
        </Hidable>

        <Hidable
          className={!isMobile && !copyingOptionId ? 'hidden' : undefined}
        >
          <Button
            hue="blue"
            variant="solid"
            onClick={() => pasteOption()}
            disabled={!copyingOptionId}
          >
            <IconPaste />
            PASTE
          </Button>
        </Hidable>
      </Flex>

      <Flex
        alignItems="center"
        gap={[1, 2]}
        flexDirection={['row-reverse', 'row']}
        justifyContent={['space-between', 'flex-end']}
      >
        <Hidable
          className={!isMobile && !canClearActions ? 'hidden' : undefined}
        >
          <Button
            hue="yellow"
            variant="flat"
            onClick={clearActions}
            disabled={!canClearActions}
          >
            CLEAR CHANGES
          </Button>
        </Hidable>

        <Button
          hue="blue"
          variant={autoSumEnabled ? 'solid' : 'outlined'}
          onClick={toggleAutoSumEnabled}
        >
          {autoSumEnabled ? <IconAutoSumOn /> : <IconAutoSumOff />}
          AUTO-SUM {autoSumEnabled ? 'ENABLED' : 'DISABLED'}
        </Button>
      </Flex>
    </Flex>
  );
};

export default CustomOptionsToolbar;
