import { Flex, Grid } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import { NavItem } from '@odo/components/layouts/app-container';
import Tooltip from '@odo/components/widgets/tooltip';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import { cssColor } from '@odo/utils/css-color';
import { FaArrowCircleRight as IconXTD } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { BASE_PATH } from '@odo/screens/deal/editor/constants';
import { conditionalJoin } from '@odo/utils/string';
import { useIsMobile } from '@odo/hooks/use-media';

const TitleSeparator = () => <>&nbsp;&#183;&nbsp;</>;

const Title = () => {
  const isMobile = useIsMobile();
  const currentProduct = useCurrentProduct();

  // return an empty element to keep the peace
  if (!currentProduct?.id && !currentProduct?.name) return <span />;

  const showXTD = !isMobile && currentProduct.isXTD;
  const showNew = !isMobile && currentProduct.isNew;

  return (
    <Grid
      gap={[1, 2]}
      gridTemplateColumns={conditionalJoin([
        '1fr',
        ...(showXTD ? ['auto'] : []),
        ...(showNew ? ['auto'] : []),
      ])}
      // adding this as an inline style coz having an element for `GridItem` props would break the layout
      style={{ justifySelf: 'flex-start' }}
    >
      <NavItem overflow="hidden" px={[2, 3]} py={0}>
        <Text
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {!!currentProduct?.id && (
            <>
              ID: <b style={{ fontWeight: 700 }}>{currentProduct.id}</b>
            </>
          )}

          {!!currentProduct?.brand && (
            <>
              {!!currentProduct?.id && <TitleSeparator />}
              {currentProduct.brand}
            </>
          )}

          {!!currentProduct?.name && (
            <>
              {(!!currentProduct?.id || !!currentProduct?.brand) && (
                <TitleSeparator />
              )}
              {currentProduct.name}
            </>
          )}
        </Text>
      </NavItem>

      {showXTD && (
        <Tooltip
          showDelay={250}
          content={() => 'This is an Extra Time Deal'}
          color={cssColor('palette-magenta')}
        >
          <NavItem>
            <Flex gap={[1, 2]} px={[1, 2]} alignItems="center">
              <Heading
                fontSize={[1, 2]}
                fontWeight={700}
                color={cssColor('palette-magenta')}
              >
                XTD
              </Heading>

              <IconXTD size={16} color={cssColor('palette-magenta')} />
            </Flex>
          </NavItem>
        </Tooltip>
      )}

      {showNew && (
        <NavItem>
          <Text
            px={[1, 2]}
            fontWeight={700}
            color={cssColor('palette-blue')}
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {!currentProduct.draft?.duplicatedDealId ? (
              <>This is a new deal</>
            ) : (
              <>
                This deal is a duplicate of{' '}
                <Link
                  to={`${BASE_PATH}/${currentProduct.draft.duplicatedDealId}/buyer-and-supplier`}
                  target="_blank"
                  style={{
                    all: 'unset',
                    fontWeight: 800,
                    textDecoration: 'underline',
                  }}
                >
                  #{currentProduct.draft.duplicatedDealId}
                </Link>
              </>
            )}
          </Text>
        </NavItem>
      )}
    </Grid>
  );
};

export default Title;
