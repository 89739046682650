import type { GridBoxProps } from '@odo/components/elements/layout';
import { Grid, Box } from '@odo/components/elements/layout';
import type { CssColor } from '@odo/utils/css-color';
import { cssColor } from '@odo/utils/css-color';
import styled from '@odo/lib/styled';
import StatusDot from '@odo/components/elements/status-dot';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIsMobile } from '@odo/hooks/use-media';
import { FaSave as IconSave } from 'react-icons/fa';
import { conditionalJoin } from '@odo/utils/string';
import type { Status } from '@odo/screens/deal/editor/types';
import { FaChevronDown as IconDown } from 'react-icons/fa';
import Button from '@odo/components/elements/button';
import { createPortal } from 'react-dom';
import { useDisableDocScroll } from '@odo/components/widgets/dialog';

const statusColorMap: Record<Status, CssColor> = {
  valid: 'palette-turquoise',
  warning: 'palette-yellow',
  error: 'palette-pink',
};

interface NavItemInterface {
  id: string;
  label: string;
  status?: Status;
  isActive?: boolean;
  onClick?: () => void;
  hasUnsavedChanges?: boolean;
}

const WhiteBar = styled(Box)``;

WhiteBar.defaultProps = {
  mx: [-1],
  bg: cssColor('foreground'),
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: cssColor('border'),
};

const MenuItemText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type MenuItemProps = GridBoxProps & {
  activeColor?: string;
};

const MenuItem = styled(Grid)<MenuItemProps>`
  --active-color: ${p => p.activeColor};

  background: ${cssColor('foreground')};
  color: ${cssColor('text')};
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;

  cursor: pointer;
  user-select: none;

  position: relative;
  overflow: hidden;

  &.active {
    & ${MenuItemText} {
      font-weight: 800;
    }
  }

  @media screen and (min-width: 48em) {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;

      transition: transform 250ms ease;
      transform: translateY(3px);

      background-color: var(--active-color);
    }

    &.active {
      &:after {
        transform: translateY(0px);
      }

      & ${MenuItemText} {
        font-weight: 600;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      background: ${cssColor('foreground-alt')};
    }
  }
`;

MenuItem.defaultProps = {
  activeColor: cssColor('palette-blue'),
  p: '10px 11px 11px 11px',
  gap: 3,
  backgroundColor: [cssColor('foreground'), 'none'],
};

interface NavProps {
  items: NavItemInterface[];
}

const Mobile = ({ items }: NavProps) => {
  const activeItem = useMemo(() => items.find(i => i.isActive), [items]);

  const navRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  useDisableDocScroll(expanded);

  /**
   * Close on outside click.
   */
  useEffect(() => {
    if (!expanded) return;

    const outsideClickClose = (e: MouseEvent | TouchEvent) => {
      if (
        navRef.current &&
        dropdownRef.current &&
        e.target instanceof Element &&
        !navRef.current.contains(e.target) &&
        !dropdownRef.current.contains(e.target)
      ) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', outsideClickClose);
    document.addEventListener('touchstart', outsideClickClose);

    return () => {
      document.removeEventListener('mousedown', outsideClickClose);
      document.removeEventListener('touchstart', outsideClickClose);
    };
  }, [expanded]);

  return (
    <WhiteBar ref={navRef}>
      <Grid gridTemplateColumns="1fr auto" alignItems="center">
        {activeItem ? (
          <MenuItem
            justifyContent="flex-start"
            alignItems="center"
            gap={4}
            activeColor={
              activeItem.status
                ? cssColor(statusColorMap[activeItem.status])
                : undefined
            }
            gridTemplateColumns="auto auto auto"
            onClick={() => setExpanded(!expanded)}
          >
            {activeItem.status && (
              <StatusDot bg={statusColorMap[activeItem.status]} />
            )}

            <MenuItemText>{activeItem.label}</MenuItemText>

            <IconSave
              color={
                activeItem.hasUnsavedChanges
                  ? cssColor('palette-blue')
                  : 'transparent'
              }
            />
          </MenuItem>
        ) : (
          <span />
        )}

        <Button
          hue="dark-grey"
          variant="flat"
          onClick={() => setExpanded(!expanded)}
        >
          <IconDown size={14} color={cssColor('grey-blue')} />
        </Button>
      </Grid>

      {createPortal(
        <Box
          ref={dropdownRef}
          position="fixed"
          top="79px"
          left={0}
          right={0}
          zIndex={19}
          bg={cssColor('foreground')}
          borderTopWidth="1px"
          borderBottomWidth="1px"
          borderStyle="solid"
          borderColor={cssColor('border')}
          boxShadow="1px 2px 4px -2px hsl(240deg 33.33% 20% / 25%), 1px 2px 8px -2px hsl(240deg 33.33% 20% / 10%)"
          px={2}
          style={{
            transition: 'transform 250ms ease',
            ...(!expanded ? { transform: 'translateY(-400px)' } : {}),
          }}
        >
          <Grid justifyContent="flex-start" gap={2} mx={-1}>
            {items.map(item => (
              <MenuItem
                key={item.id}
                justifyContent="flex-start"
                alignItems="center"
                gap={4}
                activeColor={
                  item.status
                    ? cssColor(statusColorMap[item.status])
                    : undefined
                }
                gridTemplateColumns="auto auto auto"
                className={item.isActive ? 'active' : undefined}
                onClick={() => {
                  setExpanded(false);
                  item.onClick && item.onClick();
                }}
              >
                {item.status && <StatusDot bg={statusColorMap[item.status]} />}

                <MenuItemText>{item.label}</MenuItemText>

                <IconSave
                  color={
                    item.hasUnsavedChanges
                      ? cssColor('palette-blue')
                      : 'transparent'
                  }
                />
              </MenuItem>
            ))}
          </Grid>
        </Box>,
        document.body
      )}
    </WhiteBar>
  );
};

const Desktop = ({ items }: NavProps) => (
  <WhiteBar>
    <Grid
      gridTemplateColumns={['1fr', `repeat(${items.length}, auto)`]}
      justifyContent={['space-between', null, 'flex-start']}
      gap={[2, null, 3, 4]}
    >
      {items.map(item => (
        <MenuItem
          key={item.id}
          alignItems="center"
          className={conditionalJoin([['active', item.isActive]])}
          activeColor={
            item.status ? cssColor(statusColorMap[item.status]) : undefined
          }
          gridTemplateColumns={
            conditionalJoin([
              ['auto', !!item.status],
              '1fr',
              ['auto', !!item.hasUnsavedChanges],
            ]) || ''
          }
          onClick={() => item.onClick && item.onClick()}
        >
          {item.status && <StatusDot bg={statusColorMap[item.status]} />}
          <MenuItemText>{item.label}</MenuItemText>
        </MenuItem>
      ))}
    </Grid>
  </WhiteBar>
);

const Wrapper = (props: NavProps) => {
  const isMobile = useIsMobile();
  if (isMobile) return <Mobile {...props} />;
  return <Desktop {...props} />;
};

export default Wrapper;
