import { breakpointsEm } from '@odo/contexts/theme/provider';
import { useState, useEffect } from 'react';

export const useIsMobile = () =>
  useMedia({
    query: `screen and (min-width: ${breakpointsEm[0]}em)`,
    invert: true,
  });

/**
 * NOTE: I think this can be improved by supporting one of two approaches:
 * 1. normal, ie. we just check if it's one of our pre-defined breakpoints
 * 2. custom, ie. can input any media query (current implementation)
 *
 * For now I'm gonna leave it as is coz we might not use this enough to make it worth the refactor.
 */
const useMedia = ({
  query,
  initialState = false,
  invert = false,
}: {
  query: string;
  initialState?: boolean;
  invert?: boolean;
}) => {
  const [isMediaActive, setIsMediaActive] = useState(initialState);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const mediaQueryList: MediaQueryList = window.matchMedia(query);
    if (!('addEventListener' in mediaQueryList)) return;

    // set immediately
    setIsMediaActive(invert ? !mediaQueryList.matches : mediaQueryList.matches);

    // listen for future changes
    const onChange = () => {
      setIsMediaActive(
        invert ? !mediaQueryList.matches : mediaQueryList.matches
      );
    };

    mediaQueryList.addEventListener('change', onChange);

    // cleanup
    return () => mediaQueryList.removeEventListener('change', onChange);
  }, [query, invert]);

  return isMediaActive;
};

export default useMedia;
