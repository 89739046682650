import type { DialogType } from '@odo/components/widgets/dialog/elements/types';
import { createPortal } from 'react-dom';
import styled from '@odo/lib/styled';
import { useEffect, useRef, useState } from 'react';
import DialogInner from '@odo/components/widgets/dialog/elements/inner';
import {
  useDisableDocScroll,
  useInertDoc,
} from '@odo/components/widgets/dialog/hooks';

export const DialogBackdrop = styled.div<{ zIndex?: number }>`
  position: fixed;
  z-index: ${props => props.zIndex || 75};
  inset: 0;
  background-color: hsl(226deg 25% 12% / 30%);
  /* TODO: I'd love to have a blur effect here, but this CSS3 blur has a huge performance impact */
  /* let's look into using some kind of blurry BG image instead later */
  /* backdrop-filter: blur(2px); */
  display: grid;
  place-content: center;

  transition: opacity 75ms ease;

  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: unset;
  }
`;

const DialogPortal = ({ children, isOpen, close, ...rest }: DialogType) => {
  const backdropRef = useRef<HTMLDivElement>(null);
  const dialogInnerRef = useRef<HTMLDivElement>(null);

  const [internalOpen, setInternalOpen] = useState(false);

  // we use a second piece of state to activate our animation after rendering
  if (internalOpen !== isOpen) setInternalOpen(isOpen);

  useInertDoc(isOpen);
  useDisableDocScroll(isOpen);

  /**
   * Close on backdrop click
   */
  useEffect(() => {
    if (!isOpen || !backdropRef.current || !dialogInnerRef.current) {
      return;
    }

    const outsideClickClose = (e: MouseEvent | TouchEvent) => {
      if (
        dialogInnerRef.current &&
        e.target instanceof Element &&
        !dialogInnerRef.current.contains(e.target)
      ) {
        e.preventDefault(); // prevents click through on mobile devices
        close();
      }
    };

    const backdrop = backdropRef.current;
    backdrop.addEventListener('mousedown', outsideClickClose);
    backdrop.addEventListener('touchstart', outsideClickClose);

    return () => {
      backdrop.removeEventListener('mousedown', outsideClickClose);
      backdrop.removeEventListener('touchstart', outsideClickClose);
    };
  }, [isOpen, close]);

  if (!isOpen) return null;

  return createPortal(
    <DialogBackdrop
      ref={backdropRef}
      className={internalOpen ? 'active' : undefined}
    >
      <div ref={dialogInnerRef}>
        <DialogInner isOpen={isOpen} close={close} {...rest}>
          {children}
        </DialogInner>
      </div>
    </DialogBackdrop>,
    document.body
  );
};

export default DialogPortal;
