import Button from '@odo/components/elements/button';
import {
  ButtonStrip,
  Checkbox,
  Input,
  Switch,
} from '@odo/components/elements/form-fields';
import { Flex, Grid } from '@odo/components/elements/layout';
import { Text } from '@odo/components/elements/typography';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import {
  useChangeProduct,
  useCurrentProduct,
  useSetState,
} from '@odo/contexts/product-editor';
import SectionWrapper from '@odo/screens/deal/editor/elements/section-wrapper';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImageGrid, ImageList, ImageUpload, VideoGrid } from './widgets';
import type { EditorProductImage, EditorProductVideo } from '@odo/types/portal';
import { shiftItem } from '@odo/utils/array';
import type { OnDropArgs } from '@odo/screens/deal/editor/images-and-videos/hooks';
import {
  useMonitorReorderImageDrop,
  useSetUniqueImagePositions,
} from '@odo/screens/deal/editor/images-and-videos/hooks';
import { dismiss, error } from '@odo/utils/toast';
import uuid from '@odo/utils/uuid';
import { processVideoUrl } from '@odo/screens/deal/editor/images-and-videos/helpers';
import Dialog from '@odo/components/widgets/dialog';
import { useResized } from '@odo/hooks/display';
import { useProduct } from '@odo/contexts/product-new';
import { SHOW_UNNECESSARY_FIELDS_WHEN_DIFFERENT } from '@odo/screens/deal/editor/constants';
import { cssColor } from '@odo/utils/css-color';
import {
  FaRegEye as IconEye,
  FaRegEyeSlash as IconEyeClosed,
  FaListUl as IconListView,
  FaThLarge as IconGridView,
  FaVideo as IconAddVideo,
} from 'react-icons/fa';
import {
  TbTrash as IconTrash,
  TbTrashOff as IconTrashOff,
} from 'react-icons/tb';
import type { IconType } from 'react-icons/lib';

const ToolbarIcon = ({ icon: Icon }: { icon: IconType }) => (
  <Icon size={14} style={{ margin: '-4px 0' }} />
);

/**
 * Persisted by device across all products. So uses localStorage.
 */
const getInitialImageView = () => {
  const persistedView = window.localStorage.getItem('image-view-mode');
  if (persistedView && ['grid', 'list'].includes(persistedView)) {
    return persistedView;
  }
  return 'grid';
};

const ImagesSection = () => {
  const originalProduct = useProduct();
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();
  const setState = useSetState();

  const setUniquePositions = useSetUniqueImagePositions();

  const [view, setView] = useState(() => getInitialImageView());
  const [selected, setSelected] = useState<string[]>([]);

  const hideDeleted = !!currentProduct.state?.hideDeletedImages;

  const sortedImages = useMemo(
    () =>
      [...(currentProduct.images || [])]
        /**
         * NOTE: on duplicates, the sizing guide image will be converted by MAG,
         * we need to still send the data but we don't want to show it in the editor.
         */
        .filter(
          i => !i.willBeConvertedToSizeInfo && (!hideDeleted || !i.shouldDelete)
        )
        .sort((a, b) => (a.position || 0) - (b.position || 0)),
    [currentProduct.images, hideDeleted]
  );

  const allSelected = useMemo(
    () => sortedImages.every(({ id }) => selected.includes(id)),
    [sortedImages, selected]
  );

  const toggleSelected = useCallback(
    (id: EditorProductImage['id']) => (select: boolean) =>
      setSelected(selected =>
        select ? [...selected, id] : selected.filter(i => i !== id)
      ),
    []
  );

  const onDrop = useCallback(
    ({ sourceId, targetId, edge }: OnDropArgs) => {
      const sourceIndex = sortedImages.findIndex(({ id }) => id === sourceId);
      const destIndex = sortedImages.findIndex(({ id }) => id === targetId);

      if (sourceIndex === -1 || destIndex === -1 || sourceIndex === destIndex) {
        return;
      }

      if (
        sortedImages[sourceIndex].position === sortedImages[destIndex].position
      ) {
        const toastId = error(
          'Images have the same position and so cannot be reordered properly.',
          {
            messageOptions: {
              action: {
                label: 'Set Unique Positions',
                callback: () => {
                  setUniquePositions(true);
                  dismiss(toastId);
                },
              },
            },
          }
        );
        return;
      }

      shiftItem({
        list: sortedImages,
        from: sourceIndex,
        to: destIndex,
        placement:
          edge && ['right', 'bottom'].includes(edge) ? 'after' : 'before',
        getPosition: image => image.position || 0,
        setPosition: (image, position) => {
          change({
            fieldId: `images.${image.id}.position`,
            label: 'Image Position',
            screen: 'images-and-videos',
            apply: to => {
              to.images = to.images
                ? to.images.map(i =>
                    i.id === image.id ? { ...i, position } : i
                  )
                : to.images;
            },
          });
        },
      });
    },
    [sortedImages, change, setUniquePositions]
  );

  useMonitorReorderImageDrop(onDrop);

  /**
   * Store image view mode in local storage for persistence.
   */
  useEffect(() => {
    window.localStorage.setItem('image-view-mode', view);
  }, [view]);

  return (
    <SectionWrapper title="Images">
      {SHOW_UNNECESSARY_FIELDS_WHEN_DIFFERENT &&
        !!originalProduct.isPhotographedByStudio && (
          <Checkbox
            label="Photographed By Studio"
            checked={!!currentProduct.isPhotographedByStudio}
            onChange={e => {
              const checked = !!e.target.checked;
              change({
                fieldId: 'isPhotographedByStudio',
                label: 'Photographed By Studio',
                screen: 'images-and-videos',
                apply: to => {
                  to.isPhotographedByStudio = checked;
                },
              });
            }}
          />
        )}

      <ImageUpload />

      <Flex gap={[3, 4]} flexDirection={['column-reverse', 'row']}>
        <ButtonStrip
          hue="blue"
          rounded
          options={[
            { id: 'grid', label: 'Grid View', icon: <IconGridView /> },
            { id: 'list', label: 'List View', icon: <IconListView /> },
          ]}
          selectedOption={{
            id: view,
            label: 'Selected',
            icon: <IconListView />,
          }}
          onSelect={option => setView(option.id)}
          containerProps={{ width: ['100%', 'fit-content'] }}
          wrapperProps={{ bg: cssColor('foreground') }}
        />

        <Switch
          label={`Hide deleted images (${
            (currentProduct.images || []).filter(i => i.shouldDelete).length
          })`}
          checked={hideDeleted}
          onChange={e => {
            const checked = e.target.checked;
            setState(to => (to = { ...to, hideDeletedImages: checked }));
          }}
        />
      </Flex>

      {/* bulk actions */}
      <Flex
        gap={[1, 2]}
        alignItems="center"
        flexWrap="wrap"
        flexDirection={['column', 'row']}
      >
        <Button
          hue="blue"
          variant="outlined"
          disabled={allSelected}
          onClick={() =>
            setSelected((currentProduct.images || []).map(({ id }) => id))
          }
        >
          Select All
        </Button>

        {selected.length > 0 && (
          <>
            <Button
              hue="blue"
              variant="outlined"
              onClick={() => setSelected([])}
            >
              Clear Selection
            </Button>

            <Text fontWeight={800}>·</Text>

            <Button
              hue="pink"
              variant="outlined"
              onClick={() =>
                sortedImages
                  .filter(image => selected.includes(image.id))
                  .forEach(image => {
                    change({
                      fieldId: `images.${image.id}.shouldDelete`,
                      label: 'Delete Image',
                      screen: 'images-and-videos',
                      apply: to => {
                        to.images = to.images
                          ? to.images.map(i =>
                              i.id === image.id
                                ? { ...i, shouldDelete: true }
                                : i
                            )
                          : to.images;
                      },
                    });
                  })
              }
            >
              <ToolbarIcon icon={IconTrash} /> Delete Selected
            </Button>

            <Button
              hue="violet"
              variant="outlined"
              onClick={() =>
                sortedImages
                  .filter(image => selected.includes(image.id))
                  .forEach(image => {
                    change({
                      fieldId: `images.${image.id}.shouldDelete`,
                      label: 'Cancel Delete',
                      screen: 'images-and-videos',
                      apply: to => {
                        to.images = to.images
                          ? to.images.map(i =>
                              i.id === image.id
                                ? { ...i, shouldDelete: false }
                                : i
                            )
                          : to.images;
                      },
                    });
                  })
              }
            >
              <ToolbarIcon icon={IconTrashOff} /> Restore Selected
            </Button>

            <Text fontWeight={800}>·</Text>

            <Button
              hue="yellow"
              variant="outlined"
              onClick={() =>
                sortedImages
                  .filter(image => selected.includes(image.id))
                  .forEach(image => {
                    change({
                      fieldId: `images.${image.id}.isHidden`,
                      label: 'Exclude Image',
                      screen: 'images-and-videos',
                      apply: to => {
                        to.images = to.images
                          ? to.images.map(i =>
                              i.id === image.id ? { ...i, isHidden: true } : i
                            )
                          : to.images;
                      },
                    });
                  })
              }
            >
              <ToolbarIcon icon={IconEye} /> Exclude Selected
            </Button>

            <Button
              hue="turquoise"
              variant="outlined"
              onClick={() =>
                sortedImages
                  .filter(image => selected.includes(image.id))
                  .forEach(image => {
                    change({
                      fieldId: `images.${image.id}.isHidden`,
                      label: 'Include Image',
                      screen: 'images-and-videos',
                      apply: to => {
                        to.images = to.images
                          ? to.images.map(i =>
                              i.id === image.id ? { ...i, isHidden: false } : i
                            )
                          : to.images;
                      },
                    });
                  })
              }
            >
              <ToolbarIcon icon={IconEyeClosed} /> Include Selected
            </Button>
          </>
        )}
      </Flex>

      {sortedImages.length === 0 ? (
        <Text color={cssColor('text-muted')} fontStyle="italic">
          No images for this deal yet.
        </Text>
      ) : view === 'grid' ? (
        <ImageGrid
          sortedImages={sortedImages}
          selected={selected}
          toggleSelected={toggleSelected}
        />
      ) : (
        <ImageList
          sortedImages={sortedImages}
          selected={selected}
          toggleSelected={toggleSelected}
        />
      )}
    </SectionWrapper>
  );
};

const VideosSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const [selected, setSelected] = useState<string[]>([]);
  const [addVideoDialog, setAddVideoDialog] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const allSelected = useMemo(
    () =>
      (currentProduct.videos || []).every(({ id }) => selected.includes(id)),
    [currentProduct.videos, selected]
  );

  const sortedVideos = useMemo(
    () =>
      [...(currentProduct.videos || [])].sort(
        (a, b) => (a.position || 0) - (b.position || 0)
      ),
    [currentProduct.videos]
  );

  const highestPosition = useMemo(() => {
    let highest = 0;
    (currentProduct.videos || []).forEach(({ position }) => {
      if (position && position > highest) {
        highest = position;
      }
    });
    return highest;
  }, [currentProduct.videos]);

  const toggleSelected = useCallback(
    (id: EditorProductVideo['id']) => (select: boolean) =>
      setSelected(selected =>
        select ? [...selected, id] : selected.filter(i => i !== id)
      ),
    []
  );

  const addVideo = useCallback(
    (url: string) => {
      const { platform, iframe, embedUrl } = processVideoUrl(url);

      if (!platform || !iframe) {
        error('Video platform should be either YouTube or Vimeo');
        return;
      }

      if (sortedVideos.map(({ raw }) => raw).includes(iframe)) {
        error("We don't support adding the same video twice");
        return;
      }

      const newId = uuid();

      const newVideo: EditorProductVideo = {
        id: newId,
        raw: iframe,
        url: embedUrl,
        platform,
        position: highestPosition + 1,
      };

      change({
        fieldId: `videos.add.${newId}`,
        label: 'Add Video',
        screen: 'images-and-videos',
        apply: to => {
          to.videos = to.videos ? [...to.videos, newVideo] : [newVideo];
        },
      });
    },
    [sortedVideos, highestPosition, change]
  );

  return (
    <SectionWrapper title="Videos">
      <Flex gap={[1, 2]} alignItems="center" flexWrap="wrap">
        <Button
          hue="blue"
          variant="solid"
          onClick={() => setAddVideoDialog(true)}
        >
          <ToolbarIcon icon={IconAddVideo} /> Add Video
        </Button>

        <Text fontWeight={800}>·</Text>

        <Button
          hue="blue"
          variant="outlined"
          disabled={allSelected}
          onClick={() =>
            setSelected((currentProduct.videos || []).map(({ id }) => id))
          }
        >
          Select All
        </Button>

        {selected.length > 0 && (
          <>
            <Button
              hue="blue"
              variant="outlined"
              onClick={() => setSelected([])}
            >
              Clear Selection
            </Button>

            <Text fontWeight={800}>·</Text>

            <Button
              hue="pink"
              variant="outlined"
              onClick={() =>
                sortedVideos
                  .filter(video => selected.includes(video.id))
                  .forEach(video => {
                    change({
                      fieldId: `videos.${video.id}.shouldDelete`,
                      label: 'Delete Video',
                      screen: 'images-and-videos',
                      apply: to => {
                        to.videos = to.videos
                          ? to.videos.map(i =>
                              i.id === video.id
                                ? { ...i, shouldDelete: true }
                                : i
                            )
                          : to.videos;
                      },
                    });
                  })
              }
            >
              <ToolbarIcon icon={IconTrash} /> Delete Selected
            </Button>

            <Button
              hue="violet"
              variant="outlined"
              onClick={() =>
                sortedVideos
                  .filter(video => selected.includes(video.id))
                  .forEach(video => {
                    change({
                      fieldId: `videos.${video.id}.shouldDelete`,
                      label: 'Cancel Delete',
                      screen: 'images-and-videos',
                      apply: to => {
                        to.videos = to.videos
                          ? to.videos.map(i =>
                              i.id === video.id
                                ? { ...i, shouldDelete: false }
                                : i
                            )
                          : to.videos;
                      },
                    });
                  })
              }
            >
              <ToolbarIcon icon={IconTrashOff} /> Restore Selected
            </Button>
          </>
        )}
      </Flex>

      {sortedVideos.length === 0 ? (
        <Text color={cssColor('text-muted')} fontStyle="italic">
          No videos for this deal yet.
        </Text>
      ) : (
        <VideoGrid
          sortedVideos={sortedVideos}
          selected={selected}
          toggleSelected={toggleSelected}
        />
      )}

      <Dialog
        title="Add Video"
        isOpen={addVideoDialog}
        close={() => setAddVideoDialog(false)}
      >
        <Flex flexDirection="column" gap={3}>
          <Input
            label="URL (Youtube/Vimeo):"
            value={videoUrl}
            onChange={e => setVideoUrl(e.target.value)}
            style={{ width: '300px' }}
          />

          <Flex justifyContent="space-between" gap={3}>
            <Button
              hue="dark-grey"
              variant="flat"
              onClick={() => setAddVideoDialog(false)}
            >
              Cancel
            </Button>

            <Button
              hue="blue"
              variant="solid"
              onClick={() => {
                addVideo(videoUrl);
                setVideoUrl('');
                setAddVideoDialog(false);
              }}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Dialog>
    </SectionWrapper>
  );
};

/**
 * NOTE: due to our usage of grid-template-columns: repeat(auto-fill, ...)
 * we end up with the parent grid being massively oversized and thus giving a ridiculous amount of overscroll.
 * Despite my best efforts, I cannot find a clean pure CSS solution.
 * And the auto-fill makes a massive difference to the layout, so we definitely wanna keep that.
 * For now, my solution is to observe the height of each section internally,
 * and then hardcode that height into the parent grids grid-template-rows property.
 */
const ObservedHeightSection = ({
  children,
  setObservedHeight,
}: {
  children: ReactNode;
  setObservedHeight: (height: number) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const resizeCallback = useCallback(
    (entry: ResizeObserverEntry) => {
      entry.contentRect && setObservedHeight(entry.contentRect.height);
    },
    [setObservedHeight]
  );

  useResized({
    ref,
    callback: resizeCallback,
    // NOTE: we want to listen for resizes on the children rather so that we can use their height for the grid
    observeFirstChild: true,
  });

  return <div ref={ref}>{children}</div>;
};

const ImagesAndVideosScreen = () => {
  const [observedImagesHeight, setObservedImagesHeight] = useState<
    number | undefined
  >();
  const [observedVideosHeight, setObservedVideosHeight] = useState<
    number | undefined
  >();

  const gridTemplateRows = [
    observedImagesHeight ? `${observedImagesHeight}px` : 'auto',
    observedVideosHeight ? `${observedVideosHeight}px` : 'auto',
  ].join(' ');

  return (
    <ErrorBoundary>
      <Grid
        gridTemplateRows={gridTemplateRows}
        alignContent="baseline"
        gap={[3, 4]}
      >
        <ObservedHeightSection setObservedHeight={setObservedImagesHeight}>
          <ImagesSection />
        </ObservedHeightSection>
        <ObservedHeightSection setObservedHeight={setObservedVideosHeight}>
          <VideosSection />
        </ObservedHeightSection>
      </Grid>
      <Overscroll />
    </ErrorBoundary>
  );
};

export default ImagesAndVideosScreen;
