import { cssColor } from '@odo/utils/css-color';
import Button from '@odo/components/elements/button';
import {
  FaCalculator as IconCalculator,
  FaListUl as IconMenu,
  FaArrowCircleRight as IconXTD,
  FaCopy as IconDuplicate,
} from 'react-icons/fa';
import {
  useCurrentProduct,
  useProductEditor,
} from '@odo/contexts/product-editor';
import { getAdminUrl } from '@odo/screens/deal/editor/helpers';
import SaveButton from '@odo/screens/deal/editor/widgets/toolbar/save-button';
import DuplicateButton from '@odo/screens/deal/editor/widgets/toolbar/duplicate-button';
import { useValidity } from '@odo/screens/deal/editor/hooks';
import { Fieldset } from '@odo/components/elements/form-fields';
import { Box, Flex, Grid } from '@odo/components/elements/layout';
import { FaUndo as IconReset } from 'react-icons/fa';
import { useIsMobile } from '@odo/hooks/use-media';
import { createPortal } from 'react-dom';
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import {
  DialogBackdrop,
  FocusTrap,
  useCloseOnEscape,
  useDisableDocScroll,
  useInertDoc,
} from '@odo/components/widgets/dialog';
import { Heading, Text } from '@odo/components/elements/typography';
import { Link } from 'react-router-dom';
import { BASE_PATH } from '@odo/screens/deal/editor/constants';
import StatusMessages from '@odo/screens/deal/editor/widgets/status-messages';
import styled from '@odo/lib/styled';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';

const DRAWER_WIDTH = 300;
const DRAWER_BUTTON_WIDTH = 40;

const TitleSeparator = () => <>&nbsp;&#183;&nbsp;</>;

const DrawerFieldset = styled(Fieldset)`
  border-width: 0;
  border-top-width: 1px;
  border-radius: 0;

  legend {
    margin-left: 16px;
  }
`;

DrawerFieldset.defaultProps = { p: 0, pt: 3 };

const ToolDrawer = () => {
  const currentProduct = useCurrentProduct();
  const validity = useValidity();
  const { canReset, reset } = useProductEditor();

  return (
    <Grid
      gap="24px"
      p={3}
      height="100vh"
      overflow="scroll"
      alignContent="flex-start"
    >
      <Grid gap={3}>
        <Text>
          {!!currentProduct?.id && (
            <>
              ID: <b style={{ fontWeight: 700 }}>{currentProduct.id}</b>
            </>
          )}

          {!!currentProduct?.brand && (
            <>
              {!!currentProduct?.id && <TitleSeparator />}
              {currentProduct.brand}
            </>
          )}

          {!!currentProduct?.name && (
            <>
              {(!!currentProduct?.id || !!currentProduct?.brand) && (
                <TitleSeparator />
              )}
              {currentProduct.name}
            </>
          )}
        </Text>

        {(currentProduct.isXTD || currentProduct.isNew) && (
          <Flex gap={3}>
            {currentProduct.isXTD && (
              <Flex gap={[1, 2]} alignItems="center">
                <Heading
                  fontSize={[1, 2]}
                  fontWeight={700}
                  color={cssColor('palette-magenta')}
                >
                  XTD
                </Heading>

                <IconXTD size={16} color={cssColor('palette-magenta')} />
              </Flex>
            )}

            {currentProduct.isNew && (
              <Text
                fontWeight={700}
                color={cssColor('palette-blue')}
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {!currentProduct.draft?.duplicatedDealId ? (
                  <>This is a new deal</>
                ) : (
                  <>
                    This deal is a duplicate of{' '}
                    <Link
                      to={`${BASE_PATH}/${currentProduct.draft.duplicatedDealId}/buyer-and-supplier`}
                      target="_blank"
                      style={{
                        all: 'unset',
                        fontWeight: 800,
                        textDecoration: 'underline',
                      }}
                    >
                      #{currentProduct.draft.duplicatedDealId}
                    </Link>
                  </>
                )}
              </Text>
            )}
          </Flex>
        )}
      </Grid>

      <DrawerFieldset>
        <legend>Actions</legend>

        <Grid gap={2}>
          <SaveButton
            status={validity?.status}
            variant="flat"
            px={0}
            disableTooltip
          />

          {!!currentProduct?.id && (
            <Button
              hue="blue"
              onClick={reset}
              variant="flat"
              disabled={!canReset}
              px={0}
            >
              <IconReset />
              Reset
            </Button>
          )}

          {!!currentProduct?.id && (
            <>
              <DuplicateButton circular={false} px={0} py={2} hue="blue">
                <IconDuplicate />
                Duplicate
              </DuplicateButton>

              <a
                href={getAdminUrl({
                  dest: 'priceCalculator',
                  productId: currentProduct.id,
                })}
                target="_blank"
                rel="noreferrer"
              >
                <Button hue="blue" variant="flat" px={2} pl={0}>
                  <IconCalculator />
                  Profit Calc
                </Button>
              </a>
            </>
          )}
        </Grid>
      </DrawerFieldset>

      <DrawerFieldset mt={-1}>
        <legend>Status</legend>

        <Grid gap={3}>
          <StatusMessages validity={validity} />
        </Grid>
      </DrawerFieldset>

      <Overscroll height="10vh" />
    </Grid>
  );
};

const DialogWrapper = () => {
  const labelId = useId();
  const backdropRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const close = useCallback(() => setIsOpen(false), []);

  if (!isOpen && showErrors) setShowErrors(false);

  useInertDoc(isOpen);
  useDisableDocScroll(isOpen);
  useCloseOnEscape({ close, disabled: !isOpen });

  /**
   * Close on backdrop click.
   */
  useEffect(() => {
    if (!isOpen || !backdropRef.current) return;
    const backdrop = backdropRef.current;

    const onClick = (e: MouseEvent | TouchEvent) => {
      e.preventDefault(); // prevents the click through on mobile devices
      close();
    };

    backdrop.addEventListener('mouseup', onClick);
    backdrop.addEventListener('touchend', onClick);

    return () => {
      backdrop.removeEventListener('mouseup', onClick);
      backdrop.removeEventListener('touchend', onClick);
    };
  }, [isOpen, close]);

  return (
    <>
      <DialogBackdrop
        ref={backdropRef}
        className={isOpen ? 'active' : undefined}
        zIndex={68}
      />

      <Box
        // accessibility props
        role="dialog"
        tabIndex={-1}
        aria-labelledby={labelId}
        aria-modal="true"
        // design props
        zIndex={70}
        position="fixed"
        left="100%"
        top="0"
        height="100dvh"
        width={DRAWER_WIDTH}
        bg={cssColor('foreground')}
        borderWidth="1px"
        borderStyle="solid"
        borderColor={cssColor('grey-ultra-light')}
        boxShadow="1px 2px 4px -2px hsl(240deg 33.33% 20% / 25%), 1px 2px 8px -2px hsl(240deg 33.33% 20% / 10%)"
        style={{
          transition: 'transform 250ms ease',
          ...(isOpen ? { transform: `translateX(-${DRAWER_WIDTH}px)` } : {}),
        }}
      >
        <Box
          position="absolute"
          top="20vh"
          left={`-${DRAWER_BUTTON_WIDTH + 1}px`}
          width={`${DRAWER_BUTTON_WIDTH}px`}
          bg={cssColor('foreground-alt')}
          borderWidth="1px"
          borderStyle="solid"
          borderColor={cssColor('grey-ultra-light')}
          borderRightWidth={0}
          borderTopLeftRadius="3px"
          borderBottomLeftRadius="3px"
          boxShadow="1px 2px 4px -2px hsl(240deg 33.33% 20% / 25%), 1px 2px 8px -2px hsl(240deg 33.33% 20% / 10%)"
          zIndex={1}
        >
          <Button
            hue="dark-grey"
            variant="flat"
            onClick={() => setIsOpen(!isOpen)}
            px={2}
            py={3}
          >
            <IconMenu size={20} color={cssColor('grey-blue')} />
          </Button>
        </Box>

        <FocusTrap>
          <ToolDrawer />
        </FocusTrap>
      </Box>
    </>
  );
};

const ConditionalRenderWrapper = () => {
  const isMobile = useIsMobile();
  if (!isMobile) return null;
  return createPortal(<DialogWrapper />, document.body);
};

export default ConditionalRenderWrapper;
