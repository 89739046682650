import { useEffect } from 'react';

interface NavigatorUAData {
  brands: Array<{ brand: string; version: string }>;
  mobile: boolean;
  platform: string;
}

type NavigatorWithUAData = Navigator & {
  userAgentData: NavigatorUAData;
};

type WindowNavigator = Navigator | NavigatorWithUAData;

const isNavigatorUserAgent = (
  navigator: WindowNavigator
): navigator is NavigatorWithUAData =>
  typeof (navigator as NavigatorWithUAData).userAgentData !== 'undefined';

/**
 * These APIs are deprecated and/or not super trustworthy.
 * But there's no way to detect lack of support for `overflow: hidden` on document.body
 * So we're gonna rely on these for now.
 * If they do fail, the impacts are not so drastic as to be breaking.
 */
const getPlatform = () =>
  isNavigatorUserAgent(window.navigator)
    ? window.navigator.userAgentData?.platform
    : navigator.platform;

let lockCount = 0;

const disableScroll = () => {
  const isIOS = /iP(hone|ad|od)|iOS/.test(getPlatform());
  const bodyStyle = document.body.style;
  // RTL <body> scrollbar
  const scrollbarX =
    Math.round(document.documentElement.getBoundingClientRect().left) +
    document.documentElement.scrollLeft;
  const paddingProp = scrollbarX ? 'paddingLeft' : 'paddingRight';
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth;
  const scrollX = bodyStyle.left ? parseFloat(bodyStyle.left) : window.scrollX;
  const scrollY = bodyStyle.top ? parseFloat(bodyStyle.top) : window.scrollY;

  bodyStyle.overflow = 'hidden';

  if (scrollbarWidth) {
    bodyStyle[paddingProp] = `${scrollbarWidth}px`;
  }

  // Only iOS doesn't respect `overflow: hidden` on document.body, and this
  // technique has fewer side effects.
  if (isIOS) {
    // iOS 12 does not support `visualViewport`.
    const offsetLeft = window.visualViewport?.offsetLeft || 0;
    const offsetTop = window.visualViewport?.offsetTop || 0;

    Object.assign(bodyStyle, {
      position: 'fixed',
      top: `${-(scrollY - Math.floor(offsetTop))}px`,
      left: `${-(scrollX - Math.floor(offsetLeft))}px`,
      right: '0',
    });
  }

  return () => {
    Object.assign(bodyStyle, {
      overflow: '',
      [paddingProp]: '',
    });

    if (isIOS) {
      Object.assign(bodyStyle, {
        position: '',
        top: '',
        left: '',
        right: '',
      });
      window.scrollTo(scrollX, scrollY);
    }
  };
};

let lockCleanup: () => void = () => void 0;

/**
 * Disable scroll.
 *
 * Stolen from Floating UIs FloatingOverlay element.
 * @see https://github.com/floating-ui/floating-ui/blob/master/packages/react/src/components/FloatingOverlay.tsx
 * We also implement their lock count system in case we ever trigger this multiple times.
 * But we don't tie it to a specific UI element.
 */
export const useDisableDocScroll = (disable: boolean) => {
  useEffect(() => {
    if (!disable) return;

    lockCount++;

    if (lockCount === 1) {
      lockCleanup = disableScroll();
    }

    return () => {
      lockCount--;
      if (lockCount === 0) lockCleanup();
    };
  }, [disable]);
};

/**
 * Makes the react root inert when true.
 */
export const useInertDoc = (inert: boolean) => {
  useEffect(() => {
    if (!inert) return;

    const root = document.getElementById('root');

    if (!root) return;

    root.setAttribute('inert', '');
    root.setAttribute('aria-hidden', 'true');

    return () => {
      root.removeAttribute('inert');
      root.removeAttribute('aria-hidden');
    };
  }, [inert]);
};

/**
 * Close on escape keyup.
 *
 * NOTE: keypress event is deprecated.
 * but still technically works for everything but the escape key.
 */
export const useCloseOnEscape = ({
  close,
  disabled,
}: {
  close: () => void;
  disabled?: boolean;
}) => {
  useEffect(() => {
    if (disabled) return;

    const escapeClose = (e: KeyboardEvent) => e.key === 'Escape' && close();

    document.addEventListener('keyup', escapeClose);
    return () => document.removeEventListener('keyup', escapeClose);
  }, [disabled, close]);
};
