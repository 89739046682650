import Button from '@odo/components/elements/button';
import Card from '@odo/components/elements/card';
import { Checkbox } from '@odo/components/elements/form-fields';
import { Box, Flex } from '@odo/components/elements/layout';
import { MdDragIndicator as IconDrag } from 'react-icons/md';
import { ImagePreview } from './';
import type { EditorProductImage } from '@odo/types/portal';
import ImageTypeOptions from '@odo/screens/deal/editor/images-and-videos/widgets/image-type-options';
import ImageActions from '@odo/screens/deal/editor/images-and-videos/widgets/image-actions';
import ImageLabel from '@odo/screens/deal/editor/images-and-videos/widgets/image-label';
import Tooltip from '@odo/components/widgets/tooltip';
import DropIndicator from '@odo/components/elements/drop-indicator';
import { useDragReorderImage } from '@odo/screens/deal/editor/images-and-videos/hooks';
import { useCallback } from 'react';
import ImagePreviewPrimitive from '@odo/screens/deal/editor/widgets/images/image-preview';
import { isNewId } from '@odo/utils/uuid';

const PREVIEW_SIZE = 125;
const PREVIEW_DIMS = { width: PREVIEW_SIZE, height: PREVIEW_SIZE };

const ImageListItem = ({
  image,
  isSelected,
  toggleSelected,
}: {
  image: EditorProductImage;
  isSelected: boolean;
  toggleSelected: (select: boolean) => void;
}) => {
  const preview = useCallback(
    () => (
      <ImagePreviewPrimitive
        url={image.url}
        file={image.file}
        dimensions={PREVIEW_SIZE}
      />
    ),
    [image.url, image.file]
  );

  const { draggableRef, dragHandleRef, dragEdge, dragState } =
    useDragReorderImage({
      id: image.id,
      allowedEdges: ['top', 'bottom'],
      previewDims: PREVIEW_DIMS,
      ...(!isNewId(image.id) && { preview }),
    });

  // position relative for placement of our drop indicator
  return (
    <Box position="relative">
      <Card
        ref={draggableRef}
        isSelected={isSelected}
        style={{
          transition: 'transform 25ms ease',
          transformOrigin: 'center left',
          ...(dragState === 'dragging'
            ? { transform: 'scale(0.9)', opacity: '0.6' }
            : {}),
        }}
      >
        <Flex
          gap={[3, 4, 6]}
          justifyContent="flex-start"
          alignItems="center"
          flexDirection={['column', 'row']}
        >
          <Flex
            gap={[2, 3]}
            flexDirection={['column', 'row']}
            alignItems="center"
          >
            <Flex gap={[1, 2]} alignItems="center">
              <Tooltip
                showDelay={1250}
                content={() => 'Drag to Move'}
                disabled={dragState !== 'idle'}
              >
                <Button
                  ref={dragHandleRef}
                  hue="dark-grey"
                  variant="flat"
                  py={1}
                  px={1}
                  style={{ cursor: 'grab' }}
                >
                  <IconDrag size={18} />
                </Button>
              </Tooltip>

              <Checkbox
                label=""
                checked={isSelected}
                onChange={e => toggleSelected(!!e.target.checked)}
              />
            </Flex>

            <ImagePreview image={image} dimensions={PREVIEW_SIZE} />
          </Flex>

          <ImageLabel image={image} />

          <Flex flexDirection="column" gap={[2, 3]}>
            <ImageTypeOptions image={image} />
          </Flex>

          <Flex gap={1}>
            <ImageActions image={image} />
          </Flex>
        </Flex>
      </Card>

      {dragEdge && <DropIndicator edge={dragEdge} gap={[2, 3]} />}
    </Box>
  );
};

export default ImageListItem;
