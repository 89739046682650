export const BASE_PATH = '/deals/editor';

export const SURCHARGE_WEIGHT_KEY = 'WEIGHT_AREA';
export const SURCHARGE_INSURANCE_KEY = 'INSURANCE';

export const ACCEPTED_IMAGE_TYPES = [
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/webp',
];

export const POTENTIAL_PROFIT_TOOLTIP_COPY =
  'The potential profit based on weighted margin multiplied by the original stock.';
export const WEIGHTED_MARGIN_TOOLTIP_COPY =
  'The weighted margin which takes the popularity of payment methods into account when calculating a per unit average.';
export const CUSTOMER_SAVINGS_TOOLTIP_COPY =
  'The displayed customer savings amount in rands/percentage (amounts are rounded).';

/**
 * We have a few fields that have been flagged as unnecessary by biz.
 * They all have a default/expected value that will be set for new deals,
 * and can always be hidden when they match this value.
 * But when they differ we might want to show them depending on biz preference.
 * This flag allows us to toggle that behaviour in future.
 * TODO: consider building a config system in future for features like this.
 */
export const SHOW_UNNECESSARY_FIELDS_WHEN_DIFFERENT = false;
