import { cssColor } from '@odo/utils/css-color';
import { Box, Flex, Grid } from '@odo/components/elements/layout';
import Button from '@odo/components/elements/button';
import {
  FaChevronDown as IconDown,
  FaTimes as IconClose,
} from 'react-icons/fa';
// custom icons stolen from elsewhere coz FA icons weren't cutting it
import { ReactComponent as IconPin } from '@odo/assets/svg/thumbtack.svg';
import { ReactComponent as IconUnpin } from '@odo/assets/svg/thumbtack-slash.svg';
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import type { Validity } from '@odo/screens/deal/editor/helpers';
import { Status } from '@odo/screens/deal/editor/types';
import StatusMessages from '@odo/screens/deal/editor/widgets/status-messages';
import Card from '@odo/components/elements/card';
import { useEffect, useRef, useState } from 'react';
import { useIsMobile } from '@odo/hooks/use-media';
import { createPortal } from 'react-dom';

interface StatusButtonProps {
  validity: Validity;
}

const getInitialPinned = () => {
  const persistedView = window.localStorage.getItem('status-pinned');
  if (persistedView && ['true', 'false'].includes(persistedView)) {
    return persistedView === 'true';
  }
  return false;
};

const StatusButton = ({ validity }: StatusButtonProps) => {
  const isMobile = useIsMobile();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(() => getInitialPinned());

  /**
   * Store is pinned in local storage for persistence.
   */
  useEffect(() => {
    window.localStorage.setItem('status-pinned', isPinned ? 'true' : 'false');
  }, [isPinned]);

  /**
   * Close on outside click.
   */
  useEffect(() => {
    if (!isOpen || isPinned) return;

    const outsideClickClose = (e: MouseEvent | TouchEvent) => {
      if (
        !anchorRef.current ||
        !dropdownRef.current ||
        !(e.target instanceof Element)
      ) {
        return;
      }

      if (
        !anchorRef.current.contains(e.target) &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', outsideClickClose);
    document.addEventListener('touchstart', outsideClickClose);

    return () => {
      document.removeEventListener('mousedown', outsideClickClose);
      document.removeEventListener('touchstart', outsideClickClose);
    };
  }, [isOpen, isPinned]);

  const { status } = validity;

  return (
    <>
      <Button
        ref={anchorRef}
        hue={
          status === Status.valid
            ? 'turquoise'
            : status === Status.error
            ? 'pink'
            : 'yellow'
        }
        variant="flat"
        px={1}
        py={1}
        onClick={() => setIsOpen(!isOpen)}
      >
        {status === Status.error && <IconExclamation width={26} height={26} />}

        {status === Status.warning && (
          <IconExclamation width={26} height={26} />
        )}

        {status === Status.valid && <IconValid width={26} height={26} />}

        {!isMobile && <IconDown size={14} color={cssColor('grey-blue')} />}
      </Button>

      {createPortal(
        <Box
          ref={dropdownRef}
          position="fixed"
          top="79px"
          right="8px"
          zIndex={18}
          width="450px"
          maxWidth="90vw"
          bg={cssColor('foreground')}
          borderWidth="1px"
          borderStyle="solid"
          borderColor={cssColor('border')}
          borderRadius="0px 0px 6px 6px"
          boxShadow="1px 2px 4px -2px hsl(240deg 33.33% 20% / 25%), 1px 2px 8px -2px hsl(240deg 33.33% 20% / 10%)"
          style={{
            transition: 'transform 250ms ease',
            ...(!isOpen ? { transform: 'translateY(-800px)' } : {}),
          }}
        >
          <Card
            borderWidth={0}
            style={{ cursor: 'auto' }}
            mainProps={{ maxHeight: 'min(450px, 60vh)', overflowY: 'scroll' }}
            footerProps={{ px: [1, 2], py: 1 }}
            footer={
              <Flex justifyContent="space-between" width="100%">
                <Button
                  hue="dark-grey"
                  variant="flat"
                  px={[1, 2]}
                  py={1}
                  onClick={() => setIsPinned(!isPinned)}
                >
                  {isPinned ? (
                    <IconUnpin
                      width={12}
                      height={12}
                      style={{ transform: 'rotate(45deg)' }}
                    />
                  ) : (
                    <IconPin
                      width={12}
                      height={12}
                      style={{ transform: 'rotate(45deg)' }}
                    />
                  )}
                </Button>

                <Button
                  hue="dark-grey"
                  variant="flat"
                  px={[1, 2]}
                  py={1}
                  onClick={() => setIsOpen(false)}
                >
                  <IconClose />
                </Button>
              </Flex>
            }
          >
            <Grid gap={[3, 4]}>
              <StatusMessages validity={validity} />
            </Grid>
          </Card>
        </Box>,
        document.body
      )}
    </>
  );
};

const Wrapper = ({
  validity,
  ...restProps
}: StatusButtonProps & { validity?: StatusButtonProps['validity'] }) => {
  if (!validity?.status) return null;
  return <StatusButton validity={validity} {...restProps} />;
};

export default Wrapper;
