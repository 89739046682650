import Card from '@odo/components/elements/card';
import { Grid, GridItem } from '@odo/components/elements/layout';
import { Heading } from '@odo/components/elements/typography';
import { useState } from 'react';
import type { NumericInput } from './types';
import PricingForm from './sections/pricing-form';
import ProfitCalculator from './sections/profit-calculator';
import type { CompareFormInheritedProps } from './sections/cost-compare';
import CostCompare from './sections/cost-compare';

const emptyNumericInput = { string: '', number: null };

const CostCalculatorScreen = (props: CompareFormInheritedProps) => {
  const [cost, setCost] = useState<NumericInput>(emptyNumericInput);
  const [price, setPrice] = useState<NumericInput>(emptyNumericInput);
  const [originalStock, setOriginalStock] =
    useState<NumericInput>(emptyNumericInput);

  const [retail, setRetail] = useState<NumericInput>(emptyNumericInput);
  const [surcharge, setSurcharge] = useState<NumericInput>(emptyNumericInput);

  const [rebateDiscount, setRebateDiscount] = useState<NumericInput>({
    string: '0',
    number: 0,
  });

  const [vatIncluded, setVatIncluded] = useState<boolean>(true);
  const [adminCost, setAdminCost] = useState<
    { id: string; label?: string } | undefined
  >({
    id: 'ADMINCOST_17_50',
    label: 'R17.50',
  });

  return (
    <Grid
      gap={[3, 4]}
      gridTemplateRows={[null, 'auto 1fr']}
      gridTemplateColumns={['1fr', '1fr 1fr']}
    >
      <Card header={<Heading fontSize={[1, 2]}>Cost & Price</Heading>}>
        <PricingForm
          cost={cost}
          setCost={setCost}
          price={price}
          setPrice={setPrice}
          originalStock={originalStock}
          setOriginalStock={setOriginalStock}
          retail={retail}
          setRetail={setRetail}
          rebateDiscount={rebateDiscount}
          setRebateDiscount={setRebateDiscount}
          surcharge={surcharge}
          setSurcharge={setSurcharge}
          adminCost={adminCost}
          setAdminCost={setAdminCost}
          vatIncluded={vatIncluded}
          setVatIncluded={setVatIncluded}
        />
      </Card>

      <Card header={<Heading fontSize={[1, 2]}>Profit Calculator</Heading>}>
        <ProfitCalculator
          cost={cost}
          price={price}
          originalStock={originalStock}
          retail={retail}
          rebateDiscount={rebateDiscount}
          surcharge={surcharge}
          adminCost={adminCost}
          vatIncluded={vatIncluded}
        />
      </Card>

      <GridItem gridColumn={['1/2', '1/3']}>
        <Card header={<Heading fontSize={[1, 2]}>Compare</Heading>}>
          <CostCompare {...props} setRetail={setRetail} />
        </Card>
      </GridItem>
    </Grid>
  );
};

export default CostCalculatorScreen;
