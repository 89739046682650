import { Flex, Grid, GridItem } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import { cssColor } from '@odo/utils/css-color';
import type { ReactNode } from 'react';
import { Fragment, useMemo } from 'react';
import { FaChevronDown as IconChevronDown } from 'react-icons/fa';
import { FiAlertCircle as IconAlert } from 'react-icons/fi';
import Card from '@odo/components/elements/card';
import * as Accordion from '@radix-ui/react-accordion';
import styled from '@odo/lib/styled';
import { formatMoney } from '@odo/utils/currency';
import Tooltip from '@odo/components/widgets/tooltip';
import type {
  Margin,
  ProfitCalculatorResult,
} from '@odo/helpers/calculations/profit/types';
import {
  CUSTOMER_SAVINGS_TOOLTIP_COPY,
  POTENTIAL_PROFIT_TOOLTIP_COPY,
  WEIGHTED_MARGIN_TOOLTIP_COPY,
} from '@odo/screens/deal/editor/constants';

const MARGIN_ACCORDION_ID = 'margins';

/**
 * NOTE: this accordion styling is quite specific to this screen for now.
 * TODO: if we find ourselves using accordions more, we should consider making a more generic component.
 */
const AccordionHeader = styled(Accordion.Header)`
  all: unset;
  display: flex;
`;

const AccordionArrow = styled(IconChevronDown)`
  transition: transform 350ms cubic-bezier(0.87, 0, 0.13, 1) 0s;
  color: ${cssColor('palette-blue')};
`;

const AccordionTrigger = styled(Accordion.Trigger)`
  all: unset;
  cursor: pointer;
  font-family: inherit;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 1;
  color: ${cssColor('palette-blue')};
  border: 1px solid ${cssColor('border')};
  background-color: ${cssColor('foreground')};
  padding: 0px 12px;
  flex: 1 1 0%;
  border-radius: 6px;

  &:focus-visible {
    outline: ${cssColor('palette-blue')} auto 1px;
    outline-offset: 4px;
  }

  &[data-state='open'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & ${AccordionArrow} {
      transform: rotate(180deg);
    }
  }
`;

const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid ${cssColor('border')};
  padding: 8px;
  box-shadow: rgb(219, 216, 224) 0px 1px 1px;
  margin-top: -1px;

  &[data-state='closed'] {
    height: 0;
  }
  &[data-state='open'] {
    height: auto;
  }
`;

const NumberBadge = styled.span`
  padding: 2px;
  background: ${cssColor('palette-pink')};
  width: 20px;
  aspect-ratio: 1 / 1;
  border-radius: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${cssColor('white')};
  font-weight: 800;
  margin-left: 12px;
`;

const borderTopStyles = { borderTop: `1px solid ${cssColor('border')}` };

const MarginRow = ({
  margin,
  withBorder,
}: {
  margin: Margin;
  withBorder?: boolean;
}) => {
  const color =
    margin.profitMargin.raw < 0 ? cssColor('palette-pink') : undefined;

  const styles = withBorder ? { style: borderTopStyles } : {};

  return (
    <>
      <Text py="6px" pl="6px" pr="12px" {...styles}>
        {margin.label}:
      </Text>

      <Heading
        py="6px"
        px="12px"
        fontSize={1}
        fontWeight={800}
        textAlign="right"
        color={color}
        {...styles}
      >
        {formatMoney(margin.profitMargin.rands)}
      </Heading>

      <Heading
        py="6px"
        pl="12px"
        pr="6px"
        fontSize={1}
        fontWeight={800}
        textAlign="right"
        color={color}
        {...styles}
      >
        {margin.profitMargin.percentage.toFixed(2)}%
      </Heading>
    </>
  );
};

const PricingCard = ({
  header,
  content,
  color,
  tooltip,
}: {
  header: ReactNode;
  content: ReactNode;
  color?: string;
  tooltip?: string;
}) => (
  <Card
    overflow="hidden"
    header={
      <Tooltip
        showDelay={250}
        placement="top"
        offset={16}
        disabled={!tooltip}
        content={() => tooltip}
      >
        <Flex gap={[1, 2]} color={cssColor('white')}>
          <Text textAlign="center" color={cssColor('white')}>
            {header}
          </Text>
          {!!tooltip && <IconAlert size={16} />}
        </Flex>
      </Tooltip>
    }
    headerProps={{
      justifyContent: 'center',
      backgroundColor: cssColor('palette-blue'),
      ...(color && { backgroundColor: color }),
    }}
  >
    <Flex justifyContent="center">
      <Heading fontWeight={800} fontSize={[2, 3]}>
        {content}
      </Heading>
    </Flex>
  </Card>
);

const ProfitPotential = ({
  profit,
  openMarginsByDefault,
  displayCustomerSavings,
}: {
  profit: ProfitCalculatorResult;
  openMarginsByDefault?: boolean;
  displayCustomerSavings?: boolean;
}) => {
  const weightedMargins = useMemo(
    () =>
      profit.margins.filter(
        m => typeof m.weight !== 'undefined' && m.weight > 0
      ),
    [profit.margins]
  );

  const countNegativeMargins = useMemo(
    () => weightedMargins.filter(m => m.profitMargin.raw < 0).length,
    [weightedMargins]
  );

  return (
    <>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gap={[2, 3]}>
        {!!profit.profitPotential && (
          <>
            {displayCustomerSavings && !!profit.customerSavings ? (
              <GridItem gridColumn="1/3">
                <PricingCard
                  header="Profit Potential"
                  tooltip={POTENTIAL_PROFIT_TOOLTIP_COPY}
                  content={formatMoney(profit.profitPotential)}
                  color={
                    profit.profitPotential < 0
                      ? cssColor('palette-pink')
                      : undefined
                  }
                />
              </GridItem>
            ) : (
              <PricingCard
                header="Profit Potential"
                tooltip={POTENTIAL_PROFIT_TOOLTIP_COPY}
                content={formatMoney(profit.profitPotential)}
                color={
                  profit.profitPotential < 0
                    ? cssColor('palette-pink')
                    : undefined
                }
              />
            )}
          </>
        )}

        {!!profit.weightedAverage && (
          <PricingCard
            header="Weighted Margin"
            tooltip={WEIGHTED_MARGIN_TOOLTIP_COPY}
            content={`${formatMoney(profit.weightedAverage.rands)} / ${
              profit.weightedAverage.percentage
            }%`}
            color={
              profit.weightedAverage.raw < 0
                ? cssColor('palette-pink')
                : undefined
            }
          />
        )}

        {displayCustomerSavings && !!profit.customerSavings && (
          <PricingCard
            header="Customer Savings"
            tooltip={CUSTOMER_SAVINGS_TOOLTIP_COPY}
            content={`R${profit.customerSavings.rands} / ${profit.customerSavings.percentage}%`}
            color={
              profit.customerSavings.raw === 0
                ? cssColor('palette-yellow')
                : profit.customerSavings.raw < 0
                ? cssColor('palette-pink')
                : undefined
            }
          />
        )}
      </Grid>

      <Accordion.Root
        type="single"
        collapsible
        defaultValue={openMarginsByDefault ? MARGIN_ACCORDION_ID : undefined}
      >
        <Accordion.Item value={MARGIN_ACCORDION_ID}>
          <AccordionHeader>
            <AccordionTrigger>
              <span>
                Margins
                {countNegativeMargins > 0 && (
                  <NumberBadge>{countNegativeMargins}</NumberBadge>
                )}
              </span>
              <AccordionArrow />
            </AccordionTrigger>
          </AccordionHeader>

          <AccordionContent>
            <Grid gap={0} gridTemplateColumns="1fr auto auto">
              {weightedMargins.map((margin, idx) => (
                <MarginRow
                  key={margin.code}
                  margin={margin}
                  withBorder={idx > 0}
                />
              ))}
            </Grid>
          </AccordionContent>
        </Accordion.Item>
      </Accordion.Root>
    </>
  );
};

export default ProfitPotential;
